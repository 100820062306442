import marquee from "https://cdn.jsdelivr.net/npm/vanilla-marquee/dist/vanilla-marquee.js";

document.addEventListener("DOMContentLoaded", function () {

	function scrollAncor(trigger, ancor) {
		const solutionBtn = document.querySelector(trigger);

		solutionBtn.addEventListener('click', (e) => {
			if (document.querySelector(ancor)) {
				e.preventDefault()
				scrollToEl(`${ancor}|500`)
			}
		})
	}

	if (document.querySelector('.solution-ancor')) {
		scrollAncor('.solution-ancor', '#solutions')
	}

	if (document.querySelector('.solution-testimonials')) {
		scrollAncor('.solution-testimonials', '#testimonials')
	}

	if (document.querySelector('.our')) {
		const cards = document.querySelectorAll('.our__card');
		const formWrapper = document.querySelector('.our-popup');
		const formName = formWrapper.querySelector('.solutions__name');
		const formDescr = formWrapper.querySelector('.solution-popup__descr .simplebar-content');
		const formBg = formWrapper.querySelector('.popup__close-bg');
		const formClose = formWrapper.querySelector('.popup__btn-close');

		cards.forEach(card => {
			card.addEventListener('click', () => {
				formName.innerHTML = card.querySelector('.our__name').innerHTML;
				formDescr.innerHTML = card.querySelector('.our__descr').innerHTML;
				formWrapper.classList.add('active');
				scrollLock.disablePageScroll();
				scrollLock.addScrollableSelector(".simplebar-content-wrapper");
			});

		});

		[formBg, formClose].forEach(btn => {
			btn.addEventListener('click', () => {
				formWrapper.classList.remove('active');
				scrollLock.clearQueueScrollLocks();
				scrollLock.enablePageScroll();
			})
		})

		if (innerWidth < 769) {
			new SimpleBar(formWrapper.querySelector('.popup__block'));
		}
	}

	const paralaxContainer = document.querySelectorAll(".paralax");

	paralaxContainer.forEach(elem => {
		let parallaxInstance = new Parallax(elem);
		parallaxInstance.enable();
	});

	AOS.init({
		startEvent: "load",
		offset: 150,
		delay: 0,
		duration: 500,
		easing: "ease",
		once: true,
	});

	// fix ios height
	if (innerWidth < 1024) {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
		window.addEventListener("resize", () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		});
	}
	// end fix ios height

	// burger-menu
	const burgerMenu = (menuSelector, burgerSelector) => {
		const menuElem = document.querySelector(menuSelector),
			layoutElem = document.querySelector(".header__layout"),
			burgerElem = document.querySelector(burgerSelector);

		const removeClass = () => {
			menuElem.classList.remove("active");
		};
		removeClass();

		menuElem.querySelectorAll("a").forEach(i => {
			i.addEventListener("click", e => {
				if (menuElem.classList.contains("active")) {
					removeClass();
				}
			});
		});

		burgerElem.addEventListener("click", () => {
			if (menuElem.classList.contains("active")) {
				removeClass();
			} else {
				menuElem.classList.add("active");
			}
		});

		window.addEventListener("resize", () => {
			if (innerWidth > 1023) {
				removeClass();
			}
		});

		layoutElem.addEventListener("click", () => {
			removeClass();
		});
	};

	if (document.querySelector(".header")) {
		burgerMenu(".header", ".header__burger");
	}
	// end burger-menu
	if (document.querySelector(".solutions--about") && innerWidth < 1024) {
		console.log(document.querySelector(".solutions").classList.contains('solutions--about'));
	}

	if (document.querySelector(".solutions") && innerWidth < 1024 && !document.querySelector(".solutions").classList.contains('solutions--about')) {
	
		new Swiper(".solutions__slider", {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 40,
			speed: 400,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			pagination: {
				el: ".solutions__pagination",
				clickable: true,
			},
			breakpoints: {
				569: {
					spaceBetween: 25,
					slidesPerView: 2,
				},
				900: {
					spaceBetween: 25,
					slidesPerView: 3,
				},
			},
		});
		
	}

	if (document.querySelector(".solutions__slider-bg")) {
		new Swiper(".solutions__slider-bg", {
			loop: false,
			effect: "fade",
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 400,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
		});
	}
	if (document.querySelector(".searches__slider")) {
		new Swiper(".searches__slider", {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 400,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				569: {
					slidesPerView: 2,
				},
			},
		});
	}
	if (document.querySelector(".history__slider")) {
		new Swiper(".history__slider", {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 59,
			speed: 400,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				569: {
					slidesPerView: 2,
				},
				1023: {
					slidesPerView: 3,
				},
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
		});
	}

	if (document.querySelector(".team")) {
		const thumbSwiper = new Swiper(".team__photo-slider", {
			spaceBetween: 10,
			slidesPerView: 1,
			freeMode: true,
			watchSlidesProgress: true,
			effect: "fade",
			speed: 600,
			noSwiping: true,
			noSwipingClass: "swiper-slide",
		});
		const mainSlider = new Swiper(".team__text-slider", {
			spaceBetween: 10,
			effect: "fade",
			speed: 600,
			thumbs: {
				swiper: thumbSwiper,
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
		});
	}

	if (document.getElementById("marquee_1")) {
		new marquee(document.getElementById("marquee_1"), {
			speed: innerWidth < 1024 ? 60 : 80,
			pauseOnHover: true,
			duplicated: true,
			delayBeforeStart: 0,
		});
	}
	if (document.getElementById("marquee_2")) {
		new marquee(document.getElementById("marquee_2"), {
			speed: innerWidth < 1024 ? 60 : 80,
			pauseOnHover: true,
			duplicated: true,
			direction: "right",
			delayBeforeStart: 0,
		});
	}

	function slideToTop (elements) {
		const circles = document.querySelectorAll(elements);

		gsap.registerPlugin(ScrollTrigger);

		circles.forEach(circle => {
			gsap.to(circle, {
				y: () =>
					1 - gsap.utils.random(0.1, 0.4) * ScrollTrigger.maxScroll(window),
				ease: "none",
				scrollTrigger: {
					start: 0,
					end: "max",
					invalidateOnRefresh: true,
					scrub: 2,
				},
			});
		});
	}

	if (document.querySelector(".hero")) {
		slideToTop(".hero .container");
	}

	//Pure smooth scroll
	const upElems = document.querySelectorAll('[href^="#"]');

	const element = document.documentElement,
		body = document.body;

	const calcScroll = upElem => {
		upElem.addEventListener("click", function (event) {
			if (!upElem.classList.contains("active")) {
				let scrollTop = Math.round(body.scrollTop || element.scrollTop);

				if (this.hash !== "") {
					event.preventDefault();
					let hashElement = document.querySelector(this.hash),
						hashElementTop = innerWidth < 1024 ? -90 : -180;
					while (hashElement.offsetParent) {
						hashElementTop += hashElement.offsetTop;
						hashElement = hashElement.offsetParent;
					}

					hashElementTop = Math.round(hashElementTop);
					smoothScroll(scrollTop, hashElementTop, this.hash);
				}
			} else {
				event.preventDefault();
			}
		});
	};

	const smoothScroll = (from, to, hash) => {
		let timeInterval = 1,
			prevScrollTop,
			speed;

		if (to > from) {
			speed = 10;
		} else {
			speed = -10;
		}

		let move = setInterval(function () {
			let scrollTop = Math.round(body.scrollTop || element.scrollTop);
			if (
				prevScrollTop === scrollTop ||
				(to > from && scrollTop >= to) ||
				(to < from && scrollTop <= to)
			) {
				clearInterval(move);
				history.replaceState(
					history.state,
					document.title,
					location.href.replace(/#.*$/g, "") + hash
				);
			} else {
				body.scrollTop += speed;
				element.scrollTop += speed;
				prevScrollTop = scrollTop;
			}
		}, timeInterval);
	};

	upElems.forEach(link => {
		calcScroll(link);
	});

	// page-pagination
	function pagePagination () {
		const bullets = document.querySelectorAll(".page-pagination a");
		let gap = innerWidth < 1024 ? 200 : 400;

		window.addEventListener("scroll", e => {
			bullets.forEach((bullet, i) => {
				let section = document.querySelector(bullet.hash).offsetTop;
				if (window.scrollY + gap > section) {
					bullets.forEach(bullet => {
						bullet.classList.remove("active");
					});
					bullet.classList.add("active");
				}
			});
		});
	}

	pagePagination();

	// benefits card
	if (document.querySelector(".benefits__card")) {
		const benefits = document.querySelectorAll(".benefits__card");

		benefits.forEach(benefit => {
			benefit.addEventListener("mouseenter", () => {
				benefits.forEach(benefit => {
					benefit.classList.remove("active");
				});
				benefit.classList.add("active");
			});
		});

		const addClassInSroll = () => {
			benefits.forEach(benefit => {
				if (
					benefit.getBoundingClientRect().top < innerHeight / 2 &&
					innerWidth < 659
				) {
					benefits.forEach(benefit => {
						benefit.classList.remove("active");
					});
					benefit.classList.add("active");
				}
			});
		};

		if (innerWidth < 659) {
			window.addEventListener("scroll", addClassInSroll);
		}
	}
});
